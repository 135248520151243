import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as bloodbag_45simulation7p3hhjQLB7Meta } from "/vercel/path0/pages/chart/bloodbag-simulation.vue?macro=true";
import { default as bloodbagNFx5ppyCTTMeta } from "/vercel/path0/pages/chart/bloodbag.vue?macro=true";
import { default as line_45and_45candlestickNFW3aqldkeMeta } from "/vercel/path0/pages/chart/line-and-candlestick.vue?macro=true";
import { default as indexY1KWHcBNrRMeta } from "/vercel/path0/pages/event/[eventSlug]/backoffice/index.vue?macro=true";
import { default as bloodbagkEe6EKdAHjMeta } from "/vercel/path0/pages/event/[eventSlug]/bloodbag.vue?macro=true";
import { default as indexby5eF3ebKAMeta } from "/vercel/path0/pages/event/[eventSlug]/index.vue?macro=true";
import { default as indexC5EGpLECn3Meta } from "/vercel/path0/pages/event/[eventSlug]/schedules/index.vue?macro=true";
import { default as instagramAn04XtEA2sMeta } from "/vercel/path0/pages/event/[eventSlug]/share/instagram.vue?macro=true";
import { default as indext2seVH8OjFMeta } from "/vercel/path0/pages/event/[eventSlug]/ticket/index.vue?macro=true";
import { default as indexCq54OQDgU0Meta } from "/vercel/path0/pages/event/[eventSlug]/volunteer/index.vue?macro=true";
import { default as minesmjItM7Qn0Meta } from "/vercel/path0/pages/event/[eventSlug]/volunteer/mine.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91participantId_93eYTU5cqcaSMeta } from "/vercel/path0/pages/queue/[queueId]/participant/[participantId].vue?macro=true";
import { default as indexo3kZyaUEIFMeta } from "/vercel/path0/pages/queue/join/index.vue?macro=true";
import { default as successOyy4x7ChvSMeta } from "/vercel/path0/pages/queue/join/success.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "chart-bloodbag-simulation",
    path: "/chart/bloodbag-simulation",
    meta: bloodbag_45simulation7p3hhjQLB7Meta || {},
    component: () => import("/vercel/path0/pages/chart/bloodbag-simulation.vue").then(m => m.default || m)
  },
  {
    name: "chart-bloodbag",
    path: "/chart/bloodbag",
    meta: bloodbagNFx5ppyCTTMeta || {},
    component: () => import("/vercel/path0/pages/chart/bloodbag.vue").then(m => m.default || m)
  },
  {
    name: "chart-line-and-candlestick",
    path: "/chart/line-and-candlestick",
    meta: line_45and_45candlestickNFW3aqldkeMeta || {},
    component: () => import("/vercel/path0/pages/chart/line-and-candlestick.vue").then(m => m.default || m)
  },
  {
    name: "event-eventSlug-backoffice",
    path: "/event/:eventSlug()/backoffice",
    meta: indexY1KWHcBNrRMeta || {},
    component: () => import("/vercel/path0/pages/event/[eventSlug]/backoffice/index.vue").then(m => m.default || m)
  },
  {
    name: "event-eventSlug-bloodbag",
    path: "/event/:eventSlug()/bloodbag",
    meta: bloodbagkEe6EKdAHjMeta || {},
    component: () => import("/vercel/path0/pages/event/[eventSlug]/bloodbag.vue").then(m => m.default || m)
  },
  {
    name: "EventPage",
    path: "/event/:eventSlug()",
    meta: indexby5eF3ebKAMeta || {},
    component: () => import("/vercel/path0/pages/event/[eventSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "event-eventSlug-schedules",
    path: "/event/:eventSlug()/schedules",
    meta: indexC5EGpLECn3Meta || {},
    component: () => import("/vercel/path0/pages/event/[eventSlug]/schedules/index.vue").then(m => m.default || m)
  },
  {
    name: "event-eventSlug-share-instagram",
    path: "/event/:eventSlug()/share/instagram",
    component: () => import("/vercel/path0/pages/event/[eventSlug]/share/instagram.vue").then(m => m.default || m)
  },
  {
    name: "event-eventSlug-ticket",
    path: "/event/:eventSlug()/ticket",
    meta: indext2seVH8OjFMeta || {},
    component: () => import("/vercel/path0/pages/event/[eventSlug]/ticket/index.vue").then(m => m.default || m)
  },
  {
    name: "event-eventSlug-volunteer",
    path: "/event/:eventSlug()/volunteer",
    component: () => import("/vercel/path0/pages/event/[eventSlug]/volunteer/index.vue").then(m => m.default || m)
  },
  {
    name: "event-eventSlug-volunteer-mine",
    path: "/event/:eventSlug()/volunteer/mine",
    meta: minesmjItM7Qn0Meta || {},
    component: () => import("/vercel/path0/pages/event/[eventSlug]/volunteer/mine.vue").then(m => m.default || m)
  },
  {
    name: "EventsListPage",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "queue-queueId-participant-participantId",
    path: "/queue/:queueId()/participant/:participantId()",
    meta: _91participantId_93eYTU5cqcaSMeta || {},
    component: () => import("/vercel/path0/pages/queue/[queueId]/participant/[participantId].vue").then(m => m.default || m)
  },
  {
    name: "queue-join",
    path: "/queue/join",
    meta: indexo3kZyaUEIFMeta || {},
    component: () => import("/vercel/path0/pages/queue/join/index.vue").then(m => m.default || m)
  },
  {
    name: "queue-join-success",
    path: "/queue/join/success",
    meta: successOyy4x7ChvSMeta || {},
    component: () => import("/vercel/path0/pages/queue/join/success.vue").then(m => m.default || m)
  }
]